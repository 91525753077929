<template>
    <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-row v-for="(item, index) in home.datas" :key="index">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-row style="margin-top: 20px;">
                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="title">
                            <span class="title_span">{{ $t('home.' + item.key) }}</span>
                        </el-col>
                    </el-row>
                    <el-row v-for="(desc, i) in item.description" :key="i" style="margin-top: 50px;">
                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="description">
                            {{ $t('home.' + desc) }}
                        </el-col>
                    </el-row>
                    <el-row v-for="(path, j) in item.images" :key="j" style="margin: 30px 0 30px 0">
                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <el-image :src="path" fit="cover" style="width: 100%;"></el-image>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-col>
    </el-row>
</template>

<script>
import home from "/public/Json/home.json";

export default {
    name: "WxHome",
    data() {
        return {
            home
        };
    }
};
</script>

<style scoped>
.title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.title_span {
    padding-bottom: 10px;
    border-bottom: 5px rgb(142, 195, 31) solid;
}

.description {
    text-align: center;
    padding: 0 10%;
}
</style>